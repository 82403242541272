import React, { useEffect, useMemo, useState } from 'react'
import { ResponsiveRow, RowBetween, RowFixed } from '../../components/Row'
import styled from 'styled-components'
import { Card } from '../../components/LineChart'
import LineChart from '../../components/LineChart/alt'
import { HideSmall, TYPE } from '../../theme'
import { MonoSpace } from '../../components/shared'
import { formatAmount, formatDollarAmount } from '../../utils/numbers'
import { unixToDate } from '../../utils/date'
import { Contract } from '@ethersproject/contracts'
import Percent from '../../components/Percent'
import { POOL_ADDRESS, useUsdtContract, useWbtccContract, useWbtcContract } from '../../hooks/useContract'
import { formatEther, formatUnits } from 'ethers/lib/utils'
import { useActiveWeb3React } from '../../hooks'
import DateRangePicker from '../../components/DateRangePicker'

const PageWrapper = styled.div`
  width: 90%;
  margin-top: -60px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: auto;
    width: 100%;
  `};
`

const ChartWrapper = styled.div`
  width: 100%;
  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};

`

const AutoColumn = styled.div<{
    $gap?: 'sm' | 'md' | 'lg' | string
    justify?: 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'space-between'
}>`
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: ${({ $gap }) =>
        ($gap === 'sm' && '8px') || ($gap === 'md' && '12px') || ($gap === 'lg' && '24px') || $gap};
    justify-items: ${({ justify }) => justify && justify};
  `

const ThemedBackgroundGlobal = styled.div<{ $backgroundColor: string }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  max-width: 100vw !important;
  height: 200vh;
  mix-blend-mode: color;
  background: ${({ $backgroundColor }) =>
        `radial-gradient(50% 50% at 50% 50%, ${$backgroundColor} 0%, rgba(255, 255, 255, 0) 100%)`};
  transform: translateY(-150vh);
`

export const DarkGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg0};
`

export default function Overview() {
    const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
    const [leftLabel, setLeftLabel] = useState<string | undefined>()
    const [usdtBalance, setUsdtBalance] = useState<bigint>()
    const [wbtccBalance, setWbtccBalance] = useState<bigint>()
    const [wbtcBalance, setWbtcBalance] = useState<bigint[]>([])
    const [btccPrice, setBtccPrice] = useState<any[]>([])
    const [wbtcPrice, setWbtcPrice] = useState<any>()
    const { account } = useActiveWeb3React()


    const [priceReport1D, setPriceReport1D] = useState<any[]>([])
    const [priceReport7D, setPriceReport7D] = useState<any[]>([])
    const [priceReport1M, setPriceReport1M] = useState<any[]>([])
    const [priceReport1Y, setPriceReport1Y] = useState<any[]>([])
    const [priceReportAll, setPriceReportAll] = useState<any[]>([])
    const [priceReportList, setPriceReportList] = useState<any>(null)

    const [selectedRange, setSelectedRange] = useState<'1D' | '7D' | '1M' | '1Y' | 'All'>('1D');

    const activeNetwork = {
        bgColor: "#fc077d",
        primaryColor: "#fc077d",
        secondaryColor: "#2172E5",
    }
    const usdtContract: Contract | null = useUsdtContract()
    const wbtccContract: Contract | null = useWbtccContract()
    const wbtcContract: Contract | null = useWbtcContract()

    useEffect(() => {
        getBtccPrice()
        getWbtcPrice()
    }, [])

    async function getUsdtBalance() {
        if (!usdtContract) throw new Error('missing dependencies')
        const value = await usdtContract.balanceOf(POOL_ADDRESS)
        setUsdtBalance(value)
    }

    async function getWbtccBalance() {
        if (!wbtccContract) throw new Error('missing dependencies')
        const value = await wbtccContract.balanceOf(POOL_ADDRESS)
        setWbtccBalance(value)
    }

    async function getWbtcBalance() {
        if (!wbtcContract) throw new Error('missing dependencies')
        const value = await wbtcContract.getReserves()
        setWbtcBalance(value)
    }

    useEffect(() => {
        if (!account) return
        getUsdtBalance();
    }, [usdtContract, account])

    useEffect(() => {
        if (!account) return
        getWbtccBalance();
    }, [wbtccContract, account])


    useEffect(() => {
        if (!account) return
        getWbtcBalance();
    }, [wbtcContract, account])


    const tvlValue = useMemo(() => {
        if (liquidityHover) {
            return formatDollarAmount(liquidityHover, 2, true)
        } else {
            return '-'
        }
    }, [liquidityHover])

    const getBtccPrice = async () => {
        try {
            const response = await fetch('https://service.bitcoincode.technology/system/open/api/priceReport', {
                method: 'POST',
            }).then(res => res.json())
            if (response?.code === 200 && response?.data?.length > 0) {
                setBtccPrice(response.data)
            }
        } catch (error) {
            return
        }
    }

    const getWbtcPrice = async () => {
        try {
            const response = await fetch('https://service.bitcoincode.technology/system/open/api/price/WBTC', {
                method: 'POST',
            }).then(res => res.json())
            if (response?.code === 200 && response?.data?.length > 0) {
                setWbtcPrice(response.data)
            }
        } catch (error) {
            return
        }
    }

    // const usdtValue = usdtBalance ? formatAmount(Number(formatUnits(usdtBalance, 6))) : '--'
    // const wbtccValue = wbtccBalance ? formatAmount(Number(formatEther(wbtccBalance))) : '--'
    let result1 = 0;
    let result2 = 0;
    if(wbtcBalance.length > 0) {
        result1 = Number(formatUnits(wbtcBalance[0], 18))
        result2 = Number(formatUnits(wbtcBalance[1], 18))
    }
    const wbtcValue = formatAmount(result2 * wbtcPrice);
    const wbtc2Value = wbtccBalance ? formatAmount(result1 + Number(formatEther(wbtccBalance))) : '--';
   
    const formattedBtccPriceData = useMemo(() => {
        if (btccPrice) {
            const formatted = btccPrice.map((day) => {
                return {
                    time: unixToDate(day.timestamp),
                    value: day.price,
                }
            })
            const filtered = filterLastItemsByTime(formatted)
            return filtered
        } else {
            return []
        }
    }, [btccPrice])

    const protocolData = {
        btccPrice: formattedBtccPriceData?.slice(-1)[0]?.value,
        btccPriceChange: formattedBtccPriceData?.length > 1 ? (formattedBtccPriceData.slice(-1)[0]?.value - formattedBtccPriceData.slice(-2)[0]?.value) : 0
    }

    const totalwbtcc = wbtccBalance ? (result1 + Number(formatEther(wbtccBalance))) * (protocolData?.btccPriceChange || 0) : 0
    const totalValue = (usdtBalance) ? formatAmount(Number(formatUnits(usdtBalance, 6)) * 2 + totalwbtcc) : '--'


    useEffect(() => {
        if (liquidityHover === undefined && protocolData) {
            setLiquidityHover(protocolData.btccPrice)
        }
    }, [liquidityHover, protocolData])

    // Get BTCC Price Chart Data
    const getPriceReport = async (url: string) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
            }).then(res => res.json())
            if (response?.code === 200 && response?.data?.length > 0) {
                const result = response.data.map((day: any) => {
                    return {
                        time: day.timestamp,
                        value: day.price,
                    }
                })
                return result
            }
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        getPriceReport('https://service.bitcoincode.technology/system/open/api/priceReport_1D').then(data => {
            setPriceReport1D(data)
        })
        getPriceReport('https://service.bitcoincode.technology/system/open/api/priceReport_7D').then(data => {
            setPriceReport7D(data)
        })
        getPriceReport('https://service.bitcoincode.technology/system/open/api/priceReport_1M').then(data => {
            setPriceReport1M(data)
        })
        getPriceReport('https://service.bitcoincode.technology/system/open/api/priceReport_1Y').then(data => {
            setPriceReport1Y(data)
        })
        getPriceReport('https://service.bitcoincode.technology/system/open/api/priceReport_All').then(data => {
            setPriceReportAll(data)
        })
    }, [])

    useEffect(() => {
        setPriceReportList({
            '1D': priceReport1D,
            '7D': priceReport7D,
            '1M': priceReport1M,
            '1Y': priceReport1Y,
            'All': priceReportAll
        })
        setSelectedRange('1D');
    }, [priceReport1D, priceReport7D, priceReport1M, priceReport1Y, priceReportAll])

    const handleRangeChange = (newRange: '1D' | '7D' | '1M' | '1Y' | 'All') => {
        setSelectedRange(newRange);
    };

    const priceReport = priceReportList?.[selectedRange] || [];

    return (
        <PageWrapper id="overviw-page">
            <ThemedBackgroundGlobal $backgroundColor={activeNetwork.bgColor} />
            <AutoColumn $gap="16px">
                <TYPE.main>BTCC Overview</TYPE.main>
                <ResponsiveRow>
                    <DarkGreyCard>
                        <AutoColumn $gap="4px">
                            <TYPE.mediumHeader fontSize="16px">Total Pool Size</TYPE.mediumHeader>
                            <TYPE.largeHeader fontSize="32px">
                                <MonoSpace>{totalValue}</MonoSpace>
                            </TYPE.largeHeader>
                        </AutoColumn>
                    </DarkGreyCard>
                    {/* <DarkGreyCard style={{ margin: 'auto 16px' }}>
                        <AutoColumn $gap="4px">
                            <TYPE.mediumHeader fontSize="16px">USDT Value in Pool</TYPE.mediumHeader>
                            <TYPE.largeHeader fontSize="32px">
                                <MonoSpace>{usdtValue} </MonoSpace>
                            </TYPE.largeHeader>
                        </AutoColumn>
                    </DarkGreyCard> */}
                    <DarkGreyCard style={{ margin: 'auto 16px' }}>
                        <AutoColumn $gap="4px">
                            <TYPE.mediumHeader fontSize="16px">WBTC Value in Pool</TYPE.mediumHeader>
                            <TYPE.largeHeader fontSize="32px">
                                <MonoSpace>{wbtcValue}</MonoSpace>
                            </TYPE.largeHeader>
                        </AutoColumn>
                    </DarkGreyCard>
                    <DarkGreyCard>
                        <AutoColumn $gap="4px">
                            <TYPE.mediumHeader fontSize="16px">BTCC Value in Pool</TYPE.mediumHeader>
                            <TYPE.largeHeader fontSize="32px">
                                <MonoSpace>{wbtc2Value} </MonoSpace>
                            </TYPE.largeHeader>
                        </AutoColumn>
                    </DarkGreyCard>
                </ResponsiveRow>
                <ResponsiveRow>
                    <ChartWrapper>
                        <LineChart
                            value={liquidityHover}
                            data={priceReport}
                            height={220}
                            minHeight={332}
                            color={activeNetwork.primaryColor}
                            label={leftLabel}
                            setValue={setLiquidityHover}
                            setLabel={setLeftLabel}
                            topLeft={
                                <AutoColumn $gap="4px">
                                    <TYPE.mediumHeader fontSize="16px">BTCC Price</TYPE.mediumHeader>
                                    <TYPE.largeHeader fontSize="32px">
                                        <MonoSpace>{tvlValue} </MonoSpace>
                                    </TYPE.largeHeader>
                                    <TYPE.main fontSize="12px" height="14px">
                                        {leftLabel ? <MonoSpace>{leftLabel} (UTC)</MonoSpace> : null}
                                    </TYPE.main>
                                </AutoColumn>
                            }
                            isOverview={true}
                            selectedRange={selectedRange}
                           
                        />
                        <DateRangePicker selectedRange={selectedRange} onRangeChange={handleRangeChange} />
                    </ChartWrapper>
                </ResponsiveRow>
                <HideSmall>
                    <DarkGreyCard>
                        <RowBetween>
                            <RowFixed>
                                <RowFixed mr="20px">
                                    <TYPE.main mr="4px">BTCC Price 24H: </TYPE.main>
                                    <TYPE.label mr="4px">{formatDollarAmount(protocolData?.btccPrice)}</TYPE.label>
                                    <Percent value={protocolData?.btccPriceChange} wrap={true} />
                                </RowFixed>
                            </RowFixed>
                        </RowBetween>
                    </DarkGreyCard>
                </HideSmall>
            </AutoColumn>
        </PageWrapper>
    )
}


interface DataItem {
    time: string;
    value: number;
}

function filterLastItemsByTime(items: DataItem[]): DataItem[] {
    const lastItemsMap = new Map<string, DataItem>();

    items.forEach(item => {
        lastItemsMap.set(item.time, item);
    });

    return Array.from(lastItemsMap.values());
}