import React from 'react';
import styled from 'styled-components';

type TimeRange = '1D' | '7D' | '1M' | '1Y' | 'All';

interface DateRangePickerProps {
  selectedRange: TimeRange;
  onRangeChange: (newRange: TimeRange) => void;
}

const StyledToggleButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  padding: 8px;
  background-color: #eff2f5;
  border-radius: 12px;
  border: 1px solid #ddd;
  width: 240px;
  position: absolute;
  top: 20px;
  right: 20px;
  height: 46px;
`;

const StyledToggleButton = styled.button<{ selected: boolean }>`
  border: none;
  border-radius: 8px;
  background-color: ${({ selected }) => (selected ? '#FFFFFF' : '#EFF2F5')};
  padding: 6px 12px;
  color: ${({ selected }) => (selected ? '#0D1421' : '#616E85')};
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: ${({ selected }) => (selected ? 'none' : 'none')};

  &:hover {
    background-color: rgb(248, 250, 253);
  }
`;

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  selectedRange,
  onRangeChange,
}) => {
  const handleRangeChange = (newRange: TimeRange) => {
    if (newRange !== selectedRange) {
      onRangeChange(newRange);
    }
  };

  return (
    <StyledToggleButtonGroup>
      <StyledToggleButton
        selected={selectedRange === '1D'}
        onClick={() => handleRangeChange('1D')}
      >
        1D
      </StyledToggleButton>
      <StyledToggleButton
        selected={selectedRange === '7D'}
        onClick={() => handleRangeChange('7D')}
      >
        7D
      </StyledToggleButton>
      <StyledToggleButton
        selected={selectedRange === '1M'}
        onClick={() => handleRangeChange('1M')}
      >
        1M
      </StyledToggleButton>
      <StyledToggleButton
        selected={selectedRange === '1Y'}
        onClick={() => handleRangeChange('1Y')}
      >
        1Y
      </StyledToggleButton>
      <StyledToggleButton
        selected={selectedRange === 'All'}
        onClick={() => handleRangeChange('All')}
      >
        All
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default DateRangePicker;
